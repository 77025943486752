@import "../settings/colors";
@import "../settings/type";
@import "../settings/font-family";
@import "../tools/type";

/**
 * Global box sizing option
 *
 * Type: String
 */

$iota-base-initialize-box-sizing: 'border-box';


/**
 * Makes all heading tags ( h1 - h6 ) to be equal
 * to your body size.  It forces you to use heading
 * tags with focus on your semantics and not on the
 * way they look.
 *
 * Type: Boolean
 */

$iota-base-initialize-heading-size: false;


/**
 * Enables normalize and resets for the HTML4 form
 * elements
 *
 * Type: Boolean
 */

$iota-base-initialize-form-elements: false;


/**
 * Enables normalize and resets for the HTML5 form
 * elements
 *
 * Type: Boolean
 */

$iota-base-initialize-html5-form-elements: false;


@import 'node_modules/iotacss/base/initialize';

svg {
  vertical-align: middle;
}

// set base font styles
body, input, button, select, option, textarea {
  @include base-font-family;
  @include iota-type( $type-body-small );
}

// reset focus styles on Firefox
button::-moz-focus-inner {
  border: 0;
}

html,
body {
  height: 100%
}

body {
  background: $white;
  overflow-x: hidden;
  overflow-y: auto
}
