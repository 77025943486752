@import 'font';
@import 'initialize';

.dummy-data {
  background: repeating-linear-gradient(
    135deg,
    transparent,
    transparent 10px,
    rgba(255, 0, 0, 0.4) 10px,
    rgba(255, 0, 0, 0.4) 20px
  )
}