/// font-family settings ///


// variabels
$font-base-path: '../fonts/';
$base-font-family: 'Roboto', "Helvetica Neue", sans-serif;

/// mixins ///
@mixin base-font-family {
  font-family: $base-font-family;
}

