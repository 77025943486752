@import "../settings/font-weight";
@import "../settings/type";

/**
 * Allows you to change the default type name from .o-type-.
 *
 * Type: String
 */

$iota-objs-type-namespace: 'type-';


/**
 * Allows you to specify typography sizes.
 *
 * Type: Map
 */

$iota-objs-type-sizes: $type-map;


@import 'node_modules/iotacss/objects/type';

h1 {
  @extend .o-type-h1;
}

h2 {
  @extend .o-type-h2;
}

h3 {
  @extend .o-type-h3;
}
h4 {
  @extend .o-type-h4;
}
h5 {
  @extend .o-type-h5;
}


// other typography styles
.o-type-large {
  @include type-large-additional-styles
}

.o-type-h1 {
  @include type-h1-additional-styles
}

.o-type-h2 {
  @include type-h2-additional-styles
}

.o-type-h3 {
  @include type-h3-additional-styles
}

.o-type-h4 {
  @include type-h4-additional-styles
}

.o-type-h5 {
  @include type-h5-additional-styles
}

.o-type-subtitle-1 {
  @include type-subtitle-1-additional-styles
}

.o-type-subtitle-2 {
  @include type-subtitle-2-additional-styles
}

.o-type-body-large {
  @include type-body-large-additional-styles
}

.o-type-body-small {
  @include type-body-small-additional-styles
}

.o-type-overline {
  @include type-overline-additional-styles
}

.o-type-body {
  @include type-body-additional-styles
}

.o-type-button {
  @include type-button-additional-styles
}
