/**
 * Namespace classes
 *
 * Type: String
 */

$iota-utils-pull-namespace: 'pull-';


/**
 * Size delimiter. Defaults to \/. Ex: .u-pull-1/3
 *
 * Type: String
 */

$iota-utils-pull-delimiter: $iota-global-delimiter;


/**
 * Columns to populate pull utility for
 *
 * Type: Unitless Number / List
 */

$iota-utils-pull-columns: $iota-global-columns;


/**
 * Enables / Disables breakpoint specific classes
 *
 * Type: Boolean
 */

$iota-utils-pull-res: true;


/**
 * Breakpoints map. Allows you to create breakpoints only
 * for the pull responsive utility.
 *
 * Type: Map
 */

$iota-utils-pull-breakpoints: $iota-global-breakpoints;


@import 'node_modules/iotacss/utilities/pull';
