/**
 * Size utility delimiter. Default to \/. Ex: .u-1/3.
 *
 * Type: String
 */

$iota-utils-size-delimiter: \/;  


/**
 * Size utility columns list
 *
 * Type: List
 */

$iota-utils-size-columns: $iota-global-columns;


/**
 * Enables / Disables breakpoint specific classes
 *
 * Type: Boolean
 */

$iota-utils-size-res: true;

  
/**
 * Breakpoints map. Overrides the breakpoints map only
 * for margin utility.
 *
 * Type: Map
 */

$iota-utils-size-breakpoints: $iota-global-breakpoints;


/**
 * Namespace classes
 * 
 * Type: String
 */

$iota-utils-size-namespace: '';


@import 'node_modules/iotacss/utilities/size';
