/**
 * Enables / Disables breakpoint specific classes
 *
 * Type: Boolean
 */

$iota-utils-display-res: true;


/**
 * Breakpoints map. Overrides the breakpoints map only
 * for display utility.
 *
 * Type: Map
 */

$iota-utils-display-breakpoints: $iota-global-breakpoints;


/**
 * Namespace classes
 *
 * Type: String
 */

$iota-utils-display-namespace         : '';
$iota-utils-display-block-name        : 'block';
$iota-utils-display-hidden-name       : 'hidden';
$iota-utils-display-inline-name       : 'inline';
$iota-utils-display-inline-block-name : 'inline-block';


@import 'node_modules/iotacss/utilities/display';

.u-inline-flex {
  display: inline-flex !important;
}

@include iota-breakpoint('md') {
  .u-inline-flex\@md {
      display: inline-flex !important;
    }
}

@include iota-breakpoint('lg') {
  .u-inline-flex\@lg {
    display: inline-flex !important;
  }
}

.u-display-inherit {
  display: inherit !important;;
}

@include iota-breakpoint('md') {
  .u-display-inherit\@md {
    display: inherit !important;;
  }
}

@include iota-breakpoint('lg') {
  .u-display-inherit\@lg {
    display: inherit !important;;
  }
}

