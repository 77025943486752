// Fill Utility




// Options

$iota-utils-fill-namespace : 'fill-' !default;

$iota-utils-fill-names     : $colors-map;


// Helper Local Variables
$iota-utils-fill-var-color: $iota-global-utilities-namespace + $iota-utils-fill-namespace;




// Color Utilities

@each $color-name, $color-value in $iota-utils-fill-names {

  .#{$iota-utils-fill-var-color + $color-name} {
    color: #{$color-value} !important;
  }

}
