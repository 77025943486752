/**
 * Background color names
 *
 * Type: Map
 */

$iota-utils-bgcolor-names: $colors-map;


/**
 * Namespace classes
 *
 * Type: String
 */

$iota-utils-bgcolor-namespace: 'bgcolor-';


@import 'node_modules/iotacss/utilities/bgcolor';
