:root {
  --circle-size : 24px
}

.o-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: var(--circle-size);
  width: var(--circle-size);
}
