/**
 * Color names
 *
 * Type: Map
 */

$iota-utils-color-names: $colors-map;


/**
 * Namespace classes
 *
 * Type: String
 */

$iota-utils-color-namespace: 'color-';


@import 'node_modules/iotacss/utilities/color';
