@import "colors";

:root {
    --color-black: #{$black};
    --color-white: #{$white};
    --color-white-02: #{$white-02};

  --color-primary-purple-80: #{$primary-purple-80};
  --color-primary-purple-70: #{$primary-purple-70};
  --color-primary-purple-60: #{$primary-purple-60};
  --color-primary-purple-50: #{$primary-purple-50};
  --color-primary-purple-40: #{$primary-purple-40};
  --color-primary-purple-30: #{$primary-purple-30};
  --color-primary-purple-20: #{$primary-purple-20};
  --color-primary-purple-10: #{$primary-purple-10};

  --color-primary-pink-100: #{$primary-pink-100};
  --color-primary-pink-90: #{$primary-pink-90};
  --color-primary-pink-80: #{$primary-pink-80};
  --color-primary-pink-70: #{$primary-pink-70};
  --color-primary-pink-60: #{$primary-pink-60};
  --color-primary-pink-50: #{$primary-pink-50};
  --color-primary-pink-40: #{$primary-pink-40};
  --color-primary-pink-30: #{$primary-pink-30};
  --color-primary-pink-20: #{$primary-pink-20};
  --color-primary-pink-10: #{$primary-pink-10};

  --color-secondary-100: #{$secondary-100};
  --color-secondary-90: #{$secondary-90};
  --color-secondary-80: #{$secondary-80};
  --color-secondary-70: #{$secondary-70};
  --color-secondary-60: #{$secondary-60};
  --color-secondary-50: #{$secondary-50};
  --color-secondary-40: #{$secondary-40};
  --color-secondary-30: #{$secondary-30};
  --color-secondary-20: #{$secondary-20};
  --color-secondary-10: #{$secondary-10};

  --color-muted-purple-110: #{$muted-purple-110};
  --color-muted-purple-100: #{$muted-purple-100};
  --color-muted-purple-90: #{$muted-purple-90};
  --color-muted-purple-80: #{$muted-purple-80};
  --color-muted-purple-70: #{$muted-purple-70};
  --color-muted-purple-60: #{$muted-purple-60};
  --color-muted-purple-50: #{$muted-purple-50};
  --color-muted-purple-40: #{$muted-purple-40};
  --color-muted-purple-30: #{$muted-purple-30};
  --color-muted-purple-20: #{$muted-purple-20};
  --color-muted-purple-10: #{$muted-purple-10};

  --color-grey-110: #{$grey-110};
  --color-grey-100: #{$grey-100};
  --color-grey-90: #{$grey-90};
  --color-grey-80: #{$grey-80};
  --color-grey-70: #{$grey-70};
  --color-grey-60: #{$grey-60};
  --color-grey-50: #{$grey-50};
  --color-grey-40: #{$grey-40};
  --color-grey-30: #{$grey-30};
  --color-grey-20: #{$grey-20};
  --color-grey-10: #{$grey-10};

  --color-blue-100: #{$blue-100};
  --color-blue-90: #{$blue-90};
  --color-blue-80: #{$blue-80};
  --color-blue-70: #{$blue-70};
  --color-blue-60: #{$blue-60};
  --color-blue-50: #{$blue-50};
  --color-blue-40: #{$blue-40};
  --color-blue-30: #{$blue-30};
  --color-blue-20: #{$blue-20};
  --color-blue-10: #{$blue-10};

  --color-red-100: #{$red-100};
  --color-red-90: #{$red-90};
  --color-red-80: #{$red-80};
  --color-red-70: #{$red-70};
  --color-red-60: #{$red-60};
  --color-red-50: #{$red-50};
  --color-red-40: #{$red-40};
  --color-red-30: #{$red-30};
  --color-red-20: #{$red-20};
  --color-red-10: #{$red-10};

  --color-teal-100: #{$teal-100};
  --color-teal-90: #{$teal-90};
  --color-teal-80: #{$teal-80};
  --color-teal-70: #{$teal-70};
  --color-teal-60: #{$teal-60};
  --color-teal-50: #{$teal-50};
  --color-teal-40: #{$teal-40};
  --color-teal-30: #{$teal-30};
  --color-teal-20: #{$teal-20};
  --color-teal-10: #{$teal-10};

  --color-misty-60: #{$misty-60};
  --color-misty-50: #{$misty-50};

  --color-ocean-60: #{$ocean-60};
  --color-ocean-50: #{$ocean-50};
}
