@use '@angular/material' as mat;
@use 'blab-material-color-palettes' as blab-palettes;
@use '../settings/colors' as colors;

@include mat.core();

$blab-palette-primary: mat.define-palette(blab-palettes.$palette-pink, 400, 100, 900);
$blab-palette-accent: mat.define-palette(blab-palettes.$palette-seagreen, 900, 700, 900);
$blab-palette-warn: mat.define-palette(blab-palettes.$palette-red, 700, 200, 800);

$blab-material-theme: mat.define-light-theme((
  color: (
    primary: $blab-palette-primary,
    accent: $blab-palette-accent,
    warn: $blab-palette-warn
  ),
  typography: mat.define-typography-config(),
  density: 0,
));

@include mat.all-component-themes($blab-material-theme);

.mat-expansion-panel {
  &:not(.mat-expanded) {
    .mat-expansion-panel-header {
      &:hover:not([aria-disabled=true]) {
        background: colors.$grey-40;
      }
    }
  }
  .mat-expansion-panel-header {
    &,
    &:hover,
    &:hover ,
    &:hover:not([aria-disabled=true]),
    &.mat-expanded:hover,
    &.mat-expanded:focus {
      background: colors.$grey-40;
    }

    &.mat-expanded {
      height: 48px;
    }
  }

  .mat-expansion-panel-header-title {
    @extend .o-type-overline;
    color: colors.$black;
  }

  .mat-expansion-panel-body {
    padding: 24px 0 48px;
  }
}

.mat-accordion {
  .mat-expansion-panel:first-of-type {
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

.c-formfield-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
}

.mat-mdc-checkbox {
  &.mat-mdc-checkbox--white {
    --mdc-checkbox-unselected-pressed-icon-color: #{colors.$white};
    --mdc-checkbox-unselected-hover-icon-color: #{colors.$white};
    --mdc-checkbox-unselected-focus-icon-color: #{colors.$white};
    --mdc-checkbox-selected-pressed-icon-color: #{colors.$white};
    --mdc-checkbox-selected-hover-icon-color: #{colors.$white};
    --mdc-checkbox-selected-focus-icon-color: #{colors.$white};
    --mdc-checkbox-selected-icon-color: #{colors.$white};
    --mdc-checkbox-unselected-icon-color: #{colors.$white};
    --mdc-theme-text-primary-on-background: #{colors.$white};
    --mdc-checkbox-selected-checkmark-color: #{colors.$secondary-90}
  }
}

.mat-button-toggle-group-appearance-standard {
  &.mat-button-toggle-group {
    border: 0;
  }
}
.mat-button-toggle {
  &.mat-button-toggle-appearance-standard {
    background: colors.$grey-20;
    color: colors.$grey-110;

    &.mat-button-toggle-checked {
      background: colors.$secondary-100;
      color: colors.$white;
    }

    .mat-button-toggle-label-content {
      line-height: 56px !important;
    }
  }
}



.mat-drawer-content {
  overflow: hidden !important;
  display: flex !important;
  flex-direction: column;
}
