
.u-height-1\/1 {
  height: 100%;
}

@include iota-breakpoint('md') {
  .u-height-1\/1\@md {
    height: 100%;
  }
}

@include iota-breakpoint('lg') {
  .u-height-1\/1\@lg {
    height: 100%;
  }
}