/* You can add global styles to this file, and also import other style files */

@import 'styles/settings/_index';

@import 'styles/settings/colors-css-vars';

@import 'styles/tools/_index';

@import 'styles/base/_index';

@import 'styles/objects/_index';
@import 'styles/objects/circle';

@import 'styles/material/_index';

@import 'styles/utilities/_index';

@import "ag-grid-community/dist/styles/ag-grid.scss";
@import "ag-grid-community/dist/styles/ag-theme-material/sass/ag-theme-material.scss";

@import "styles/ag-grid/blab-ag-grid";

//html,
//body {
//  height: 100%;
//}
//body {
//  margin: 0;
//  font-family: Roboto, "Helvetica Neue", sans-serif;
//}

// overwriting default property of matside-bar in app-navigation-bar-component
.mat-drawer-container {
  background-color: #DBDBDB !important;
}

// ensure ag-grid in tabs has full height
.mat-tab-body-wrapper {
  height: 100%;
}

.c-floating-button-wrapper {
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 0 12px 12px;
}
