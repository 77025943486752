ag-grid-angular {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 1px 0px rgba(0, 0, 0, 0.20);
}

.ag-root-wrapper {
  border-radius: 4px;
}

.ag-status-bar {
  border-right: 0 !important;
  border-left: 0 !important;
  border-bottom: 0 !important;
}

.ag-theme-material {
  letter-spacing: 0.4px;

  --ag-material-primary-color: var(--color-primary-pink-50);
  --ag-material-accent-color: var(--color-secondary-100);

  --ag-border-color: #e2e2e2;

  --ag-checkbox-indeterminate-color: var(--color-grey-80);
  --ag-checkbox-unchecked-color: var(--color-grey-80);
  --ag-checkbox-checked-color: var(--ag-material-accent-color);

  --ag-selected-tab-underline-color: var(--color-primary-pink-50);

  --ag-range-selection-border-color: var(--ag-material-accent-color);

  --ag-selected-row-background-color: #eee;
  //--ag-row-hover-color: ;

  .ag-side-bar {
    border-left: 1px solid var(--ag-border-color);
  }

  .ag-side-buttons {
    width: 32px;
  }

  .ag-column-select,
  .ag-column-panel-column-select {
    border: 0;
  }

  input[class^=ag-]:focus:not([type]),
  input[class^=ag-][type=text]:focus,
  input[class^=ag-][type=number]:focus,
  input[class^=ag-][type=tel]:focus,
  input[class^=ag-][type=date]:focus,
  input[class^=ag-][type=datetime-local]:focus,
  textarea[class^=ag-]:focus {
    border-bottom-color: var(--ag-material-accent-color);
  }

  .ag-picker-field-wrapper:focus {
    box-shadow: 0 0 0 1px var(--ag-material-accent-color);
  }
}

