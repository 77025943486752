$black: #000;
$white: #fff;
$white-02: rgba($white, 0.2);

$primary-purple-80: #5B013F; // primary BTN
$primary-purple-70: #841160;
$primary-purple-60: #B06599;
$primary-purple-50: #DE96C8;
$primary-purple-40: #F6BBE4;
$primary-purple-30: #F5D0EA;
$primary-purple-20: #FAE2F2;
$primary-purple-10: #FFF1FB;

$primary-pink-100: #550115;
$primary-pink-90: #770822;
$primary-pink-80: #991232;
$primary-pink-70: #BB1F44;
$primary-pink-60: #DD2F59;
$primary-pink-50: #FF4370; // schaltflächen
$primary-pink-40: #FF6D90;
$primary-pink-30: #FF97B0;
$primary-pink-20: #FFC2D0;
$primary-pink-10: #FFECF0;

$secondary-100: #016059;
$secondary-90: #0A8279;
$secondary-80: #18A499; // secondary BTN
$secondary-70: #2BC6BA;
$secondary-60: #42E8DB;
$secondary-50: #5AFFF2;
$secondary-40: #7FFFF5;
$secondary-30: #A4FFF8;
$secondary-20: #C9FFFB;
$secondary-10: #EEFFFD;

$muted-purple-110: #100B0F;
$muted-purple-100: #3F2D39; // BG-color
$muted-purple-90: #584151;
$muted-purple-80: #725669;
$muted-purple-70: #8B6C82;
$muted-purple-60: #A4839A;
$muted-purple-50: #DE96C8;
$muted-purple-40: #F6BBE4;
$muted-purple-30: #F5D0EA;
$muted-purple-20: #FAE2F2;
$muted-purple-10: #FFF1FB;

$grey-110: #0C0B0B; // headline text
$grey-100: #3C3838; // body text
$grey-90: #534F4F; // info text / overline
$grey-80: #696666;
$grey-70: #807E7E; // text muted
$grey-60: #979797;
$grey-50: #ADADAD;
$grey-40: #C4C4C4; // tab header
$grey-30: #DBDBDB;
$grey-20: #F1F1F1;
$grey-10: #EEEEEE;

$blue-100: #192453;
$blue-90: #293875;
$blue-80: #3C4E97;
$blue-70: #5367B9;
$blue-60: #6D83DB;
$blue-50: #8BA1FD;
$blue-40: #A6B7FF;
$blue-30: #C0CCFF;
$blue-20: #D9E1FF;
$blue-10: #F3F6FF;

$red-100: #560015;
$red-90: #7F001E;
$red-80: #A80028; // error
$red-70: #D11441;
$red-60: #FA3060;
$red-50: #FF567E;
$red-40: #FF7B9A;
$red-30: #FFA0B7;
$red-20: #FFA0B7;
$red-10: #FFEAEF;

$teal-100: #1A3C48;
$teal-90: #2A5665;
$teal-80: #3C7082;
$teal-70: #518C9F;
$teal-60: #69A7BC;
$teal-50: #84C4DA;
$teal-40: #A2E1F7;
$teal-30: #C1EFFF;
$teal-20: #D9E1FF;
$teal-10: #F6FDFF ;

$misty-60: #85B2AE;
$misty-50: #9EC9C5;

$ocean-60: #5F828E;
$ocean-50: #84AFBE;


$colors-map:  (
  black: $black,
  white: $white,
  white-02: $white-02,

  primary-purple-80: $primary-purple-80,
  primary-purple-70: $primary-purple-70,
  primary-purple-60: $primary-purple-60,
  primary-purple-50: $primary-purple-50,
  primary-purple-40: $primary-purple-40,
  primary-purple-30: $primary-purple-30,
  primary-purple-20: $primary-purple-20,
  primary-purple-10: $primary-purple-10,

  primary-pink-100: $primary-pink-100,
  primary-pink-90: $primary-pink-90,
  primary-pink-80: $primary-pink-80,
  primary-pink-70: $primary-pink-70,
  primary-pink-60: $primary-pink-60,
  primary-pink-50: $primary-pink-50,
  primary-pink-40: $primary-pink-40,
  primary-pink-30: $primary-pink-30,
  primary-pink-20: $primary-pink-20,
  primary-pink-10: $primary-pink-10,

  secondary-100: $secondary-100,
  secondary-90: $secondary-90,
  secondary-80: $secondary-80,
  secondary-70: $secondary-70,
  secondary-60: $secondary-60,
  secondary-50: $secondary-50,
  secondary-40: $secondary-40,
  secondary-30: $secondary-30,
  secondary-20: $secondary-20,
  secondary-10: $secondary-10,

  muted-purple-110: $muted-purple-110,
  muted-purple-100: $muted-purple-100,
  muted-purple-90: $muted-purple-90,
  muted-purple-80: $muted-purple-80,
  muted-purple-70: $muted-purple-70,
  muted-purple-60: $muted-purple-60,
  muted-purple-50: $muted-purple-50,
  muted-purple-40: $muted-purple-40,
  muted-purple-30: $muted-purple-30,
  muted-purple-20: $muted-purple-20,
  muted-purple-10: $muted-purple-10,

  grey-110: $grey-110,
  grey-100: $grey-100,
  grey-90: $grey-90,
  grey-80: $grey-80,
  grey-70: $grey-70,
  grey-60: $grey-60,
  grey-50: $grey-50,
  grey-40: $grey-40,
  grey-30: $grey-30,
  grey-20: $grey-20,
  grey-10: $grey-10,

  blue-100: $blue-100,
  blue-90: $blue-90,
  blue-80: $blue-80,
  blue-70: $blue-70,
  blue-60: $blue-60,
  blue-50: $blue-50,
  blue-40: $blue-40,
  blue-30: $blue-30,
  blue-20: $blue-20,
  blue-10: $blue-10,

  red-100: $red-100,
  red-90: $red-90,
  red-80: $red-80,
  red-70: $red-70,
  red-60: $red-60,
  red-50: $red-50,
  red-40: $red-40,
  red-30: $red-30,
  red-20: $red-20,
  red-10: $red-10,

  teal-100: $teal-100,
  teal-90: $teal-90,
  teal-80: $teal-80,
  teal-70: $teal-70,
  teal-60: $teal-60,
  teal-50: $teal-50,
  teal-40: $teal-40,
  teal-30: $teal-30,
  teal-20: $teal-20,
  teal-10: $teal-10,

  misty-60: $misty-60,
  misty-50: $misty-50,

  ocean-60: $ocean-60,
  ocean-50: $ocean-50,
);
