/**
 * Weight sizes
 *
 * Type: Map
 */

$iota-utils-weight-sizes: $font-weight-map;


/**
 * Namespace classes
 *
 * Type: String
 */

$iota-utils-weight-namespace: 'weight-';


@import 'node_modules/iotacss/utilities/weight';
