@import './font-weight';

$type-large-size: 83px;
$type-large-height: 99.6px;
$type-large-size-tablet: 83px;
$type-large-height-tablet: 99.6px;
$type-large-size-mobile: 83px;
$type-large-height-mobile: 99.6px;

$type-h1-size: 67px;
$type-h1-height: 80.4px;
$type-h1-size-tablet: 67px;
$type-h1-height-tablet: 80.4px;
$type-h1-size-mobile: 67px;
$type-h1-height-mobile: 80.4px;

$type-h2-size: 53px;
$type-h2-height: 63.6px;
$type-h2-size-tablet: 53px;
$type-h2-height-tablet: 63.6px;
$type-h2-size-mobile: 53px;
$type-h2-height-mobile: 63.6px;

$type-h3-size: 43px;
$type-h3-height: 51.6px;
$type-h3-size-tablet: 43px;
$type-h3-height-tablet: 51.6px;
$type-h3-size-mobile: 43px;
$type-h3-height-mobile: 51.6px;

$type-h4-size: 34px;
$type-h4-height: 40.8px;
$type-h4-size-tablet: 34px;
$type-h4-height-tablet: 40.8px;
$type-h4-size-mobile: 34px;
$type-h4-height-mobile: 40.8px;

$type-h5-size: 20px;
$type-h5-height: 28px;
$type-h5-size-tablet: 20px;
$type-h5-height-tablet: 28px;
$type-h5-size-mobile: 20px;
$type-h5-height-mobile: 28px;

$type-subtitle-1-size: 27px;
$type-subtitle-1-height: 32.4px;
$type-subtitle-1-size-tablet: 27px;
$type-subtitle-1-height-tablet: 32.4px;
$type-subtitle-1-size-mobile: 27px;
$type-subtitle-1-height-mobile: 32.4px;

$type-subtitle-2-size: 22px;
$type-subtitle-2-height: 26.4px;
$type-subtitle-2-size-tablet: 22px;
$type-subtitle-2-height-tablet: 26.4px;
$type-subtitle-2-size-mobile: 22px;
$type-subtitle-2-height-mobile: 26.4px;

$type-body-large-size: 18px;
$type-body-large-height: 25.2px;
$type-body-large-size-tablet: 18px;
$type-body-large-height-tablet: 25.2px;
$type-body-large-size-mobile: 18px;
$type-body-large-height-mobile: 25.2px;

$type-body-small-size: 14px;
$type-body-small-height: 18.48px;
$type-body-small-size-tablet: 14px;
$type-body-small-height-tablet: 18.48px;
$type-body-small-size-mobile: 14px;
$type-body-small-height-mobile: 18.48px;

$type-overline-size: 11px;
$type-overline-height: 13.2px;
$type-overline-size-tablet: 11px;
$type-overline-height-tablet: 13.2px;
$type-overline-size-mobile: 11px;
$type-overline-height-mobile: 13.2px;

$type-body-size: 9px;
$type-body-height: 10.8px;
$type-body-size-tablet: 9px;
$type-body-height-tablet: 10.8px;
$type-body-size-mobile: 9px;
$type-body-height-mobile: 10.8px;

$type-button-size: 14px;
$type-button-height: 19.6px;
$type-button-size-tablet: 14px;
$type-button-height-tablet: 19.6px;
$type-button-size-mobile: 14px;
$type-button-height-mobile: 19.6px;


$type-large: (
  null : ($type-large-size-mobile, $type-large-height-mobile),
  md   : ($type-large-size-tablet, $type-large-height-tablet),
  lg   : ($type-large-size, $type-large-height)
);

$type-h1: (
  null : ($type-h1-size-mobile, $type-h1-height-mobile),
  md   : ($type-h1-size-tablet, $type-h1-height-tablet),
  lg   : ($type-h1-size, $type-h1-height)
);

$type-h2: (
  null : ($type-h2-size-mobile, $type-h2-height-mobile),
  md   : ($type-h2-size-tablet, $type-h2-height-tablet),
  lg   : ($type-h2-size, $type-h2-height)
);

$type-h3: (
  null : ($type-h3-size-mobile, $type-h3-height-mobile),
  md   : ($type-h3-size-tablet, $type-h3-height-tablet),
  lg   : ($type-h3-size, $type-h3-height)
);

$type-h4: (
  null : ($type-h4-size-mobile, $type-h4-height-mobile),
  md   : ($type-h4-size-tablet, $type-h4-height-tablet),
  lg   : ($type-h4-size, $type-h4-height)
);

$type-h5: (
  null : ($type-h5-size-mobile, $type-h5-height-mobile),
  md   : ($type-h5-size-tablet, $type-h5-height-tablet),
  lg   : ($type-h5-size, $type-h5-height)
);

$type-subtitle-1: (
  null : ($type-subtitle-1-size-mobile, $type-subtitle-1-height-mobile),
  md   : ($type-subtitle-1-size-tablet, $type-subtitle-1-height-tablet),
  lg   : ($type-subtitle-1-size, $type-subtitle-1-height)
);

$type-subtitle-2: (
  null : ($type-subtitle-2-size-mobile, $type-subtitle-2-height-mobile),
  md   : ($type-subtitle-2-size-tablet, $type-subtitle-2-height-tablet),
  lg   : ($type-subtitle-2-size, $type-subtitle-2-height)
);

$type-body-large: (
  null : ($type-body-large-size-mobile, $type-body-large-height-mobile),
  md   : ($type-body-large-size-tablet, $type-body-large-height-tablet),
  lg   : ($type-body-large-size, $type-body-large-height)
);

$type-body-small: (
  null : ($type-body-small-size-mobile, $type-body-small-height-mobile),
  md   : ($type-body-small-size-tablet, $type-body-small-height-tablet),
  lg   : ($type-body-small-size, $type-body-small-height)
);

$type-overline: (
  null : ($type-overline-size-mobile, $type-overline-height-mobile),
  md   : ($type-overline-size-tablet, $type-overline-height-tablet),
  lg   : ($type-overline-size, $type-overline-height)
);

$type-body: (
  null : ($type-body-size-mobile, $type-body-height-mobile),
  md   : ($type-body-size-tablet, $type-body-height-tablet),
  lg   : ($type-body-size, $type-body-height)
);

$type-button: (
  null : ($type-button-size-mobile, $type-button-height-mobile),
  md   : ($type-button-size-tablet, $type-button-height-tablet),
  lg   : ($type-button-size, $type-button-height)
);

@mixin type-large-additional-styles {
  font-weight: $font-weight-regular;
 /* letter-spacing: 0.02em;*/ // noch nicht angegeben in figma
}

@mixin type-h1-additional-styles {
  font-weight: $font-weight-regular;
 /* letter-spacing: 0.02em;*/
}

@mixin type-h2-additional-styles {
  font-weight: $font-weight-regular;
/*  letter-spacing: 0.02em;*/
}

@mixin type-h3-additional-styles {
  font-weight: $font-weight-regular;
/*  letter-spacing: 0.02em;*/
}

@mixin type-h4-additional-styles {
  font-weight: $font-weight-regular;
/*  letter-spacing: 0.02em;*/
}

@mixin type-h5-additional-styles {
  font-weight: $font-weight-bold;
/*  letter-spacing: 0.02em;*/
}

@mixin type-subtitle-1-additional-styles {
  font-weight: $font-weight-regular;
/*  letter-spacing: 0.04em;*/
}

@mixin type-subtitle-2-additional-styles {
  font-weight: $font-weight-regular;
/*  letter-spacing: 0.04em;*/
}

@mixin type-body-large-additional-styles {
  font-weight: $font-weight-regular;
  /*  letter-spacing: 0.04em;*/
}

@mixin type-body-small-additional-styles {
  font-weight: $font-weight-regular;
  /*  letter-spacing: 0.04em;*/
}

@mixin type-overline-additional-styles {
  font-weight: $font-weight-regular;
  text-transform: uppercase;
  letter-spacing: 1.1px;
}

@mixin type-body-additional-styles {
  font-weight: $font-weight-regular;
  /*  letter-spacing: 0.04em;*/
}

@mixin type-button-additional-styles {
  font-weight: $font-weight-regular;
  /*  letter-spacing: 0.04em;*/
}


$type-map: (
  'large': $type-large, // generates class "o-type-h1" with definitions for font-size and line-height
  'h1': $type-h1,
  'h2': $type-h2,
  'h3': $type-h3,
  'h4': $type-h4,
  'h5': $type-h5,
  'overline': $type-overline,
  'subtitle-1': $type-subtitle-1,
  'subtitle-2': $type-subtitle-2,
  'body-large': $type-body-large,
  'body-small': $type-body-small,
  'body-overline': $type-overline,
  'body': $type-body,
  'button': $type-button,
);
