/**
 * Margin default gutter. Use a number for a simple size
 * and a map for responsive.
 *
 * Type: Number / Map
 */

$iota-utils-margin-default: $iota-global-gutter-default;


/**
 * Margin extra gutters.
 *
 * Type: Map
 */

$iota-utils-margin-extra: $spacing-map;


/**
 * Enables / Disables responsive classes
 *
 * Type: Boolean
 */

$iota-utils-margin-res: true;


/**
 * Breakpoints map. Overrides the breakpoints map only
 * for margin utility.
 *
 * Type: Map
 */

$iota-utils-margin-breakpoints: $iota-global-breakpoints;


/**
 * Namespace classes
 *
 * Type: String
 */

$iota-utils-margin-namespace        : 'm';
$iota-utils-margin-top-name         : 't';
$iota-utils-margin-right-name       : 'r';
$iota-utils-margin-bottom-name      : 'b';
$iota-utils-margin-left-name        : 'l';
$iota-utils-margin-vertical-name    : 'v';
$iota-utils-margin-horizontal-name  : 'h';


@import 'node_modules/iotacss/utilities/margin';

.u-ml-auto {
  margin-left: auto;
}

.u-mr-auto {
  margin-right: auto;
}
