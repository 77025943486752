
$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-black: 900;

// font-weight map
$font-weight-map: (
  'extra-light': $font-weight-extra-light,
  'light': $font-weight-light,
  'regular': $font-weight-regular,
  'medium': $font-weight-medium,
  'semi': $font-weight-semi-bold,
  'bold': $font-weight-bold,
  'extra-bold': $font-weight-extra-bold,
  'black': $font-weight-black,
);
