
.u-overflow-hidden {
  overflow: hidden !important;
}

.u-overflow-auto {
  overflow-y: auto !important;
}

.u-overflow-y-auto {
  overflow-y: auto !important;
}

.u-overflow-x-auto {
  overflow-x: auto !important;
}

.u-overflow-x-hidden {
  overflow-x: hidden !important;
}

.u-overflow-scroll {
  overflow: scroll !important;
}

.u-overflow-y-scroll {
  overflow-y: scroll !important;
}

.u-overflow-x-scroll {
  overflow-x: scroll !important;
}

.u-overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}